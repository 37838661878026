@tailwind base;
@tailwind components;
@tailwind utilities;

/* * {
  font-family: "League Spartan", sans-serif;
  font-weight: bold;
} */

.heading {
  font-family: "League Spartan", sans-serif;
  font-weight: bold;
}

.body {
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  line-height: 190%;
}

/* Custom scrollbar hide utility */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hero {
  background-image: url("/src/imgs/hero.jpg");
  background-position: bottom center;
  background-size: 200%;
}

@media only screen and (max-width: 500px) {
  .hero {
    background-position: bottom center;
    background-size: 300%;
  }
}

@media only screen and (max-width: 370px) {
  .hero {
    background-position: bottom center;
    background-size: 400%;
  }
}

@media only screen and (min-width: 1025px) {
  .hero {
    background-position: bottom center;
    background-size: 100%;
  }
}
